import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Occupancy } from '../../occupancy.class';
import { IOccupancyRequestOptions, OccupancyService } from '../../occupancy.service';
import { CommonModule } from '@angular/common';
import { IOccupancyDto } from '../../occupancy.dto';

@Component({
  selector: 'plugin-occupancy-picker',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './occupancy-picker.plugin.html',
  styleUrl: './occupancy-picker.plugin.scss'
})
export class OccupancyPickerPlugin {
  @Input()
  set options(value: IOccupancyRequestOptions) {
    this._options = value;
    this.loadOptions();
  }
  get options(): IOccupancyRequestOptions {
    return this._options;
  }

  @Output() onDelete: EventEmitter<IOccupancyDto> = new EventEmitter();

  list: Occupancy[] = [];
  _options: IOccupancyRequestOptions = {};
  private dataLoaded: boolean = false;

  constructor(
    private occupancyService: OccupancyService,
  ) {}

  private loadOptions() {
    if (!this.dataLoaded && this.options && this.options.personIds && this.options.personIds.length>0) {
      this.occupancyService.list(this.options).subscribe(l => {
        if (l) {
          this.list = l;
          this.dataLoaded = true;
        }
      })
    }
  }

  public reloadOptions() {
    this.dataLoaded = false;
    this.loadOptions();
  }

  deleteOccupancy(occupancy: Occupancy) {
    console.log(`Suppression de l'occupation avec l'ID ${occupancy.id}`);
    this.onDelete.emit(occupancy.getDto());
    // this.occupancyService.delete(occupancy.id, occupancy.idContact).subscribe(() => {
    //   this.dataLoaded = false;
    //   this.loadData();
    // });
  }

  completeOccupancy(occupancy: Occupancy) {
    console.log(`Terminer l'occupation avec l'ID ${occupancy.id}`);
    // occupancy.dtEnd = new Date();
    // // Appeler le service pour mettre à jour l'occupation avec la nouvelle date de fin
    // this.occupancyService.update(occupancy).subscribe(updatedOccupancy => {
    //   console.log(`Occupation avec l'ID ${occupancy.id} mise à jour avec la date de fin`, updatedOccupancy.dtEnd);
    // });
  }

}
