import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ApiService } from '../../api/api.service';
import { Occupancy } from './occupancy.class';
import { environment } from '../../../environments/environment';
import { IOccupancyDto } from './occupancy.dto';

@Injectable({
  providedIn: 'root'
})
export class OccupancyService {

  constructor(
    private apiService: ApiService,
  ) { }

  list(
    options: IOccupancyRequestOptions = {},
  ): Observable<Occupancy[] | null> {
    console.log('Fetching occupancies with options:', options);
    const url: string = `${environment.api.url}${environment.api.endpoint.occupancyList}`;
    const params = new HttpParams()
      .set('lpe', options.personIds ? options.personIds.join(',') : '')
      .set('add', options.loadAddress ? +options.loadAddress : 0)
      .set('per', options.loadPerson ? +options.loadPerson : 0);
    console.log(url, options);
    return this.apiService.get<IOccupancyDto[]>(url, { params }).pipe(
      map(dtos => dtos.map(dto => new Occupancy(dto))),
      catchError(this.apiService.handleError.bind(this)),
    );
  }

  get(
    id: number,
    options: IOccupancyRequestOptions = {},
  ): Observable<Occupancy | null> {
    const url: string = `${environment.api.url}${environment.api.endpoint.occupancyGet}/${id}`;
    const params = new HttpParams()
      .set('add', options.loadAddress ? +options.loadAddress : 0)
      .set('per', options.loadPerson ? +options.loadPerson : 0);
    return this.apiService.get<IOccupancyDto>(url, { params }).pipe(
      map(dto => new Occupancy(dto)),
      catchError(this.apiService.handleError.bind(this)),
    );
  }

  add(
    occupancy: Occupancy
  ): Observable<Occupancy | null> {
      const url: string = `${environment.api.url}${environment.api.endpoint.occupancyAdd}/${occupancy.idContact}`;
      
      return this.apiService.post<IOccupancyDto>(url, occupancy.getDto()).pipe(
          map(dto => {
              return new Occupancy(dto);
          }),
          catchError(error => {
              console.error('Erreur rencontrée dans add:', error);
              return this.apiService.handleError(error);
          })
      );
  }

  delete(
    id: number,
    idPerson: number,
  ): Observable<void> {
    const url: string = `${environment.api.url}${environment.api.endpoint.occupancyDelete}/${id}/p/${idPerson}`;
    let params: HttpParams = new HttpParams();
    return this.apiService.delete<IOccupancyDto>(url, { params })
  }
}

export interface IOccupancyRequestOptions {
  ids?: number[],
  id?: number,
  personId?: number,
  personIds?: number[],
  loadAddress?: boolean,
  loadPerson?: boolean,

  showContact?: boolean,
}