import { Routes } from '@angular/router';
import { TargetComponent } from './target/target/target.component';
import { BootstrapRoute } from './boot/bootstrap.route';
import { LoginRoute } from './auth/route/login/login.route';
import { DashboardRoute } from './user/route/dashboard/dashboard.route';
import { UserListRoute } from './user/route/list/user-list.route';
import { UserHomeRoute } from './user/route/home/user-home/user-home.route';
import { ProfileRoute } from './user/route/profile/profile.route';
import { UserEditRoute } from './user/route/edit/user-edit.route';
import { CollectionListRoute } from './management/route/dashboard/collection-dashboard.route';
import { UserCreateRoute } from './user/route/create/user-create.route';
import { ContactListRoute } from './contact/route/list/contact-list.route';
import { ContactEditRoute } from './contact/route/edit/contact-edit.route';
import { RentalHomeRoute } from './rental/route/home/rental-home.route';
import { AddressListRoute } from './wealth/address/route/list/address-list.route';
import { AddressEditRoute } from './wealth/address/route/edit/address-edit.route';
import { AccommodationListRoute } from './wealth/accommodation/route/list/accommodation-list.route';
import { AccommodationEditRoute } from './wealth/accommodation/route/edit/accommodation-edit.route';
import { LeaseListRoute } from './rental/lease/route/list/lease-list.route';
import { LeaseEditRoute } from './rental/lease/route/edit/lease-edit.route';
import { RentEmitRoute } from './management/rent/route/emit/rent-emit.route';
import { RentEditRoute } from './management/rent/route/edit/rent-edit.route';
import { ContractEditRoute } from './management/contract/route/contract-edit.route';
import { RentConfirmDeleteRoute } from './management/rent/route/confirm-delete/rent-confirm-delete.route';
import { LeaseStatusEditRoute } from './rental/lease/route/status-edit/lease-status-edit.route';
import { DevIssuerOccupancySelectorCOPRoute } from './dev/route/lease/test-issuer-occupancy-selector-cop/dev-issuer-occupancy-selector-cop.route';
import { WealthHomeRoute } from './wealth/route/home/wealth-home.route';


export const routes: Routes = [
    { path: '', redirectTo: 'rental/dashboard', pathMatch: 'full' }, 
    { path: 'target', component: TargetComponent },
    { path: 'login', component: LoginRoute },

    { path: 'rental/dashboard', component: DashboardRoute },
    { path: 'rental/contact/list', component: ContactListRoute},

    { path: 'services/contact/list', component: ContactListRoute},

    { path: 'accommodation/list', component: AccommodationListRoute },
    { path: 'accommodation/:id', component: AccommodationEditRoute },
    { path: 'accommodation/new', component: AccommodationEditRoute },
    
    { path: 'address/list', component: AddressListRoute },
    { path: 'address/:id', component: AddressEditRoute },

    { path: 'management', component: CollectionListRoute },
    { path: 'management/dashboard', component: CollectionListRoute },
    { path: 'management/contract/:id', component: ContractEditRoute },

    // { path: 'contact/list', component: ContactListRoute},
    { path: 'contact/new', component: ContactEditRoute},
    { path: 'contact/view/:idcontact', component: ContactEditRoute},

    { path: 'rent/:id', component: RentEditRoute },
    { path: 'rent/emit/:key', component: RentEmitRoute },
    { path: 'rent/confirmdelete/:idoperation', component: RentConfirmDeleteRoute },
    
    { path: 'rental', component: RentalHomeRoute },

    { path: 'lease/list', component: LeaseListRoute },
    { path: 'lease/new', component: LeaseEditRoute },
    { path: 'lease/:id', component: LeaseEditRoute },
    
    { path: 'lease/status/edit/:idlease', component: LeaseStatusEditRoute },

    { path: 'user', component: UserHomeRoute },
    { path: 'user/create', component: UserCreateRoute },
    { path: 'user/edit/:username', component: UserEditRoute },
    { path: 'user/list', component: UserListRoute },

    { path: 'profile', component: ProfileRoute },

    { path: 'wealth', component: WealthHomeRoute },

    { path: 'dev/issuer-occupancy-selector-cop', component: DevIssuerOccupancySelectorCOPRoute },
];
