import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TtileService {

  constructor(private titleService: Title) {}

  setAppTitle() {
    const title = `Alfred [${environment.production ? '' : environment.showEnv}]`;
    this.titleService.setTitle(title);
  }
}
