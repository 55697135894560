import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Accommodation } from '../../accommodation.class';
import { IAccommodationDto } from '../../accommodation.dto';
import { CommonModule } from '@angular/common';
import { AddressViewPlugin } from '../../../address/plugin/view/address-view.plugin';
import { AddressEditPlugin } from '../../../address/plugin/edit/address-edit.plugin';
import { FormsModule } from '@angular/forms';
import { Address } from '../../../address/address.class';
import { AddressInstanciatePlugin } from '../../../address/plugin/instanciate/address-instanciate.plugin';
import { IAddressDto } from '../../../address/address.dto';

@Component({
  selector: 'plugin-accommodation-edit',
  standalone: true,
  imports: [
    CommonModule,
    AddressViewPlugin,
    AddressEditPlugin,
    FormsModule,
    AddressInstanciatePlugin,
  ],
  templateUrl: './accommodation-edit.plugin.html',
  styleUrl: './accommodation-edit.plugin.scss'
})
export class AccommodationEditPlugin {
  public accommodation: Accommodation = new Accommodation();
  @Input()
  set accommodationDto(value: IAccommodationDto) {
    this.loadDto(value);
  }
  get accommodationDto() {
    return this.accommodation.getDto();
  }

  @Output() onSave: EventEmitter<IAccommodationDto> = new EventEmitter();
  @Output() onCancel: EventEmitter<void> = new EventEmitter();

  dataLoaded: boolean = false;
  public addressEditMode: boolean = false; 

  constructor() {};

  loadDto(dto: IAccommodationDto) {
    if (dto && !this.dataLoaded) {
      this.accommodation = new Accommodation(dto);
      this.dataLoaded = true;
    }
  }

  save() {
    this.onSave.emit(this.accommodation.getDto()); 
  }

  cancel() {
    this.onCancel.emit(); 
  }

  toggleAddressEditMode() {
    this.addressEditMode = !this.addressEditMode; 
  }

  onAddressUpdated(dto: IAddressDto) {
    this.accommodation.address = new Address(dto);
    this.toggleAddressEditMode();
  }

  get addressDto(): IAddressDto {
    if (!this.accommodation || !this.accommodation.address) {
      return (new Address).getDto();
    } else {
      return this.accommodation.address.getDto();
    }
  }
}
