<div class="accommodation-picker">
  <h2>Choisissez un hébergement</h2>

  <!-- Liste des hébergements -->
  <ul *ngIf="list.length > 0">
    <li *ngFor="let accommodation of list"
        (click)="click(accommodation.getDto())"
        (dblclick)="activate(accommodation.getDto())"
        [class.selected]="accommodation === selected">

      <div class="accommodation-details">
        <h3>{{ accommodation.name }}</h3>
        <p><strong>Description :</strong> {{ accommodation.inlineDescription || 'N/A' }}</p>

        <!-- Section Loyer de Référence et Proposé -->
        <p *ngIf="accommodation.rentByMonthReference || accommodation.feesByMonthReference">
          <strong>Référence :</strong> Loyer {{ accommodation.rentByMonthReference || 'N/A' }} €
          + Charges {{ accommodation.feesByMonthReference || 'N/A' }} €
        </p>
        <p *ngIf="accommodation.rentByMonthListed || accommodation.feesByMonthListed">
          <strong>Proposé :</strong> Loyer {{ accommodation.rentByMonthListed || 'N/A' }} €
          + Charges {{ accommodation.feesByMonthListed || 'N/A' }} €
        </p>
      </div>

      <!-- Bouton pour voir les détails -->
      <button class="btn-view" (click)="view(accommodation.getDto())">{{ options.selectButtonLabel || 'Voir' }}</button>
    </li>
  </ul>

  <!-- Aucun hébergement disponible -->
  <p *ngIf="list.length === 0">Aucun hébergement disponible.</p>
</div>
