import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IContactDto } from '../../contact.dto';
import { Contact } from '../../contact.class';
import { ContactService, IContactRequestOptions } from '../../contact.service';


/**
 * Plugin d'affichage d'une liste de contacts
 * options de sélection possibles
 * retour : sélection, activation
 */
@Component({
  selector: 'plugin-contact-picker',
  templateUrl: './contact-picker.plugin.html',
  styleUrls: ['./contact-picker.plugin.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class ContactPickerPlugin implements OnInit {
  private _options: IContactPickerPluginOptions = {};
  @Input()
  set options(value: IContactPickerPluginOptions) {
    this._options = value;
  }
  get options(): IContactPickerPluginOptions {
    return this._options;
  }

  // output
  @Output() selected = new EventEmitter<IContactDto>
  @Output() activated = new EventEmitter<IContactDto>

  list: Contact[] = [];
  selectedContact: Contact = new Contact();

  constructor(
    private contactService: ContactService,
  ) {}

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    // configuration des options contact et appel du service contact pour récupérer la liste
    const requestOptions: IContactRequestOptions = this.getPluginOptionsToContactOptions();
    // console.log(this.loadData.name, requestOptions);
    this.contactService.list(requestOptions).subscribe(data => {
      if (data) {
        this.list = data;
      } else {
        console.error(this.loadData.name, 'Erreur de récupération de la liste des contacts.');
      }
    })
  }

  selectContact(item: Contact) {
    if (item) {
      this.selectedContact = item;
      this.selected.emit(this.selectedContact.getDto());
    }
  }

  activateContact(item: Contact) {
    if (item) {
      this.activated.emit(item.getDto());
    }
  }

  isSelected(contact: Contact): boolean {
    return this.selectedContact && this.selectedContact.id === contact.id;
  }

  // extrait les options pour le service contact
  private getPluginOptionsToContactOptions(): IContactRequestOptions {
    const cro: IContactRequestOptions = {
      loadTitle: true,
    };
    if (this.options.list) {
      cro.selectIds = this.options.list.contactIdList as number[];
      cro.selectTags = this.options.list.tags ? this.options.list.tags : undefined;
    }
    return cro;
  }
}

/**
 * Structure des options d'appel du plugin
 */
export interface IContactPickerPluginOptions {
  list?: {
    contactIdList?: number[],     // sélection des contacts de la liste
    selectedItem?: number,
    tags?: string[],
  },
  allowExtendList?: {
    toAll?: boolean,
  },
}

