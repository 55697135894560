<div class="address-instanciate">
    <h2>Instancier une Adresse</h2>
  
    <!-- Formulaire pour saisir l'adresse -->
    <form [formGroup]="addressForm">
        <div class="form-group">
            <label for="country">Pays</label>
            <input id="country" list="countryList" formControlName="country" placeholder="Saisir ou choisir un pays">
            <datalist id="countryList">
              <option *ngFor="let country of countryList" [value]="country">{{ country }}</option>
            </datalist>
          </div>
  
          <div class="form-group">
            <label for="city">Ville</label>
            <input type="text" id="city" list="cityList" formControlName="city" placeholder="Saisir ou choisir une ville">
            <datalist id="cityList">
              <option *ngFor="let city of cityList" [value]="city">{{ city }}</option>
            </datalist>
          </div>
          
          <div class="form-group">
            <label for="postalCode">Code Postal</label>
            <input type="text" id="postalCode" list="postalCodeList" formControlName="postalCode" placeholder="Saisir ou choisir un code postal">
            <datalist id="postalCodeList">
              <option *ngFor="let code of postalCodeList" [value]="code">{{ code }}</option>
            </datalist>
          </div>
          
          <div class="form-group">
            <label for="deliveryService">Service de Livraison</label>
            <input type="text" id="deliveryService" list="deliveryServiceList" formControlName="deliveryService" placeholder="Saisir ou choisir un service de livraison">
            <datalist id="deliveryServiceList">
              <option *ngFor="let service of deliveryServiceList" [value]="service">{{ service }}</option>
            </datalist>
          </div>
          
          <div class="form-group">
            <label for="streetType">Type de Rue</label>
            <input type="text" id="streetType" list="streetTypeList" formControlName="streetType" placeholder="Saisir ou choisir un type de rue">
            <datalist id="streetTypeList">
              <option *ngFor="let type of streetTypeList" [value]="type">{{ type }}</option>
            </datalist>
          </div>
          
          <div class="form-group">
            <label for="streetName">Nom de la Rue</label>
            <input type="text" id="streetName" list="streetNameList" formControlName="streetName" placeholder="Saisir ou choisir un nom de rue">
            <datalist id="streetNameList">
              <option *ngFor="let street of streetNameList" [value]="street">{{ street }}</option>
            </datalist>
          </div>
          
          <div class="form-group">
            <label for="streetNumber">Numéro de Rue</label>
            <input type="text" id="streetNumber" list="streetNumberList" formControlName="streetNumber" placeholder="Saisir ou choisir un numéro de rue">
            <datalist id="streetNumberList">
              <option *ngFor="let number of streetNumberList" [value]="number">{{ number }}</option>
            </datalist>
          </div>
          
          <div class="form-group">
            <label for="buildingLocation">Localisation du Bâtiment</label>
            <input type="text" id="buildingLocation" list="buildingLocationList" formControlName="buildingLocation" placeholder="Saisir ou choisir une localisation du bâtiment">
            <datalist id="buildingLocationList">
              <option *ngFor="let location of buildingLocationList" [value]="location">{{ location }}</option>
            </datalist>
          </div>
          
          <div class="form-group">
            <label for="deliveryPoint">Point de Livraison</label>
            <input type="text" id="deliveryPoint" list="deliveryPointList" formControlName="deliveryPoint" placeholder="Saisir ou choisir un point de livraison">
            <datalist id="deliveryPointList">
              <option *ngFor="let point of deliveryPointList" [value]="point">{{ point }}</option>
            </datalist>
          </div>
          
          <div class="form-actions">
            <button type="button" class="btn-save" (click)="onUpdate()">Sauvegarder</button>
            <button type="button" class="btn-reset" (click)="onReinitialize()">Réinitialiser</button>
          </div>
          
    </form>
  </div>
  