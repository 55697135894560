import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Rent } from '../../rent/rent.class';
import { IRentRequestOptions, RentService } from '../../rent/rent.service';
import { LeasePickerPlugin } from '../../../rental/lease/plugin/picker/lease-picker.plugin';
import { ELeaseStatus } from '../../../rental/lease/leasestatus.enum';
import { ILeaseRequestOptions } from '../../../rental/lease/lease.service';
import { Lease } from '../../../rental/lease/lease.class';
import { ILeaseDto } from '../../../rental/lease/lease.dto';
import { RentPickerPlugin } from '../../rent/plugin/picker/rent-picker.plugin';
import { RentToIssuePickerPlugin } from '../../rent/plugin/rent-to-issue-picker/rent-to-issue-picker.plugin';
import { IOperationDto } from '../../../operation/operation.dto';

@Component({
  selector: 'route-collection-dashboard',
  standalone: true,
  imports: [
    CommonModule,   // Pour *ngFor, *ngIf, etc.
    RouterModule,
    RentPickerPlugin,
    LeasePickerPlugin,
    RentToIssuePickerPlugin,
  ],
  templateUrl: './collection-dashboard.route.html',
  styleUrls: ['./collection-dashboard.route.scss'],
})
export class CollectionListRoute implements OnInit {
  leaseOptions: ILeaseRequestOptions = {};
  dueRentOptions: IRentRequestOptions = {
    exStatus: ['PAID'],
  };
  paidRentOptions: IRentRequestOptions = {
    inStatus: ['PAID'],
    dateMin: this.calculateMinDate(45),
  };
  lease: Lease = new Lease();

  constructor(
    private router: Router,
    private rentService: RentService,
  ) {}

  ngOnInit(): void {
    this.leaseOptions = {
      loadAccommodation: true,
      statusList: [ELeaseStatus.RUNNING],
    }
  }

  calculateMinDate(daysAgo: number): Date {
    const date = new Date();
    date.setDate(date.getDate() - daysAgo);
    return date;  // Retourne un objet Date au lieu de string
  }

  selectLease(dto: ILeaseDto) {
    if (dto) {
      this.lease = new Lease(dto);
    }
  }

  rentToIssueActivated(dto: IOperationDto) {
    const rent: Rent = new Rent(dto);
    // stockage du rent dans le service :
    const rentKey: string = this.rentService.storeRent(rent);
    this.router.navigate(['/rent/emit', rentKey]);
  }

  rentActivated(dto: IOperationDto) {
    const rent = new Rent(dto);
    this.router.navigate(['/rent', rent.idOperation]);
  }

  manageContract(dto: ILeaseDto) {
    const lease: Lease = new Lease(dto);
    this.router.navigate(['/management/contract', lease.id]);
  }
}
