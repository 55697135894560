import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OccupancyDataEditPlugin } from '../data-edit/occupancy-data-edit.plugin';
import { AddressViewPlugin } from '../../../../wealth/address/plugin/view/address-view.plugin';
import { IOccupancyRequestOptions, OccupancyService } from '../../occupancy.service';
import { AddressService, IAddressRequestOptions } from '../../../../wealth/address/address.service';
import { Occupancy } from '../../occupancy.class';
import { AddressInstanciatePlugin } from '../../../../wealth/address/plugin/instanciate/address-instanciate.plugin';
import { IAddressDto } from '../../../../wealth/address/address.dto';
import { Address } from '../../../../wealth/address/address.class';
import { IOccupancyDto } from '../../occupancy.dto';

@Component({
  selector: 'plugin-occupancy-edit',
  standalone: true,
  imports: [
    CommonModule,
    OccupancyDataEditPlugin,
    AddressViewPlugin,
    AddressInstanciatePlugin,
  ],
  templateUrl: './occupancy-edit.plugin.html',
  styleUrl: './occupancy-edit.plugin.scss'
})

export class OccupancyEditPlugin {
  @Input()
  set options(value: IOccupancyRequestOptions) {
    if (!this.loaded) {
      console.log('activation OccupancyEditPlugin', value);
      this._options = value;
      this.loadData();
    }
  }
  
  @Output() onSaved: EventEmitter<IOccupancyDto> = new EventEmitter();
  @Output() onCanceled: EventEmitter<void> = new EventEmitter();

  occupancy: Occupancy = new Occupancy();
  loaded: boolean = false;
  _options: IOccupancyRequestOptions = { showContact: false };
  addressOptions: IAddressRequestOptions = {}
  addressDto: IAddressDto = {};

  constructor(
    private occupancyService: OccupancyService,
    private addressService: AddressService,
  ) {}

  // Charge le plugin à partir des options
  loadData() {
    if (!this.loaded) {
      if (this._options.id === 0) {
        // nouvelle occupation
        this.occupancy = new Occupancy();
        this.addressDto = (new Address()).getDto();
        this.occupancy.idContact = this._options.personId || 0;   // met à jour le contact id en input
        this.loaded = true;
      } else {
        // occupation existante
        this.occupancyService.get(this._options.id || 0).subscribe({
          next: (o) => {
            if (o) {
              this.occupancy = o;
              this.loaded = true;
            }
          },
          error: (err) => {
            console.error('Error fetching occupancy data:', err);
          },
          complete: () => {
            console.log('Finished fetching occupancy data');
          }
        });
      }
    }
  }

  // addresse reçue (instanciate)
  // le dto est instancié
  onAddressUpdated(dto: IAddressDto) {
    console.log('Début de onAddressSaved');
    console.log('DTO reçu:', dto);

    const address: Address = new Address(dto);
    console.log('Nouvelle instance Address créée:', address);

    this.addressService.instanciate(address).subscribe(a => {
        console.log('Réponse de addressService.instanciate:', a);

        if (a) {
            this.occupancy.idAddress = a.idAddress || 0;
            console.log('occupancy.idAddress mis à jour:', this.occupancy.idAddress);

            // on passe à address view
            this.occupancy.address = a;
            console.log('occupancy.address mis à jour:', this.occupancy.address);

            this.addressDto = this.occupancy.address.getDto();
            console.log('addressDto mis à jour:', this.addressDto);
        } else {
            console.warn('Aucune adresse retournée de addressService.instanciate');
        }
    }, error => {
        console.error('Erreur lors de la souscription à instanciate:', error);
    });
}


  // contrôle du plugin instanciate (address edit / view)
  get addressEditMode(): boolean {
    return this.occupancy.isNew && this.occupancy.idAddress === 0;
  }

  // sauvegarde des data (hors contact et address)
  onSave(dto: IOccupancyDto) {
    console.log('Début de onSave');
    console.log('DTO reçu dans onSave:', dto);

    // Création d'une nouvelle instance d'Occupancy avec les données du DTO
    const updatedOccupancy = new Occupancy(dto);
    console.log('Nouvelle instance d\'Occupancy créée à partir du DTO:', updatedOccupancy);

    // Conserver les valeurs critiques : idAddress et idContact de l'occupancy existant
    updatedOccupancy.idAddress = this.occupancy.idAddress;
    updatedOccupancy.idContact = this.occupancy.idContact;
    console.log('idAddress et idContact conservés:', {
        idAddress: updatedOccupancy.idAddress,
        idContact: updatedOccupancy.idContact
    });

    // Mise à jour de this.occupancy avec l'instance modifiée
    this.occupancy = updatedOccupancy;
    console.log('Occupancy mise à jour après fusion des données DTO et valeurs critiques:', this.occupancy);

    // Vérification si idAddress est valide pour procéder à la sauvegarde
    if (this.occupancy.idAddress !== 0) {
        console.log('idAddress valide, lancement de la sauvegarde dans occupancyService.add');
        
        // Sauvegarde de l'occupancy
        this.occupancyService.add(this.occupancy).subscribe(o => {
            if (o) {
                console.log('Sauvegarde réussie, émet DTO sauvegardé:', o.getDto());
                this.onSaved.emit(o.getDto());
            } else {
                console.warn('Réponse vide après sauvegarde dans occupancyService.add');
            }
        }, error => {
            console.error('Erreur lors de la tentative de sauvegarde dans occupancyService.add:', error);
        });
    } else {
        console.warn('idAddress est nul ou invalide, annulation de la sauvegarde');
    }
  }


  cancelData() {
    this.onCanceled.emit();
  }

}
