import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { ProfileStatusWidget } from '../auth/widget/profile-status/profile-status.widget';
import { TopicsWidget } from '../nav/topics/topics.widget';
import { SectionsWidget } from '../nav/sections/sections.widget';
import { CommonModule } from '@angular/common';
import { SideInfoWidget } from "../broadcast/widget/side-info/side-info.widget";
import { TtileService } from './ttile.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterModule,
    ProfileStatusWidget,
    TopicsWidget,
    SectionsWidget,
    SideInfoWidget
],
  templateUrl: './bootstrap.route.html',
  styleUrl: './bootstrap.route.scss'
})
export class BootstrapRoute implements OnInit {
  title = 'Alfred';
  isColumnVisible = false;

  constructor(
    private titleService: TtileService,
  ) {}

  ngOnInit(): void {
      this.titleService.setAppTitle();
  }

  toggleColumn() {
    console.log('toggle column');
    this.isColumnVisible = !this.isColumnVisible;

    const floatingColumn = document.getElementById('floatingColumn');
    const content = document.querySelector('.content') as HTMLElement;

    if (this.isColumnVisible) {
      floatingColumn?.classList.remove('hidden');
      content.style.marginRight = '270px'; // Rendre de la place pour la colonne flottante
    } else {
      floatingColumn?.classList.add('hidden');
      content.style.marginRight = '0'; // Utiliser tout l'espace disponible
    }
  }
}
