export enum ESection {
  // Domaine Location
  Locataires = 'SECTION_LOCATAIRES',
  ContactList = 'SECTION_CONTACT_LIST',
  LeaseList = 'SECTION_LEASE_LIST',

  // Domaine prestation
  ContractList = 'SECTION_CONTRACT',
  
  // Domaine Gestion
  Collection = 'SECTION_COLLECTION',

  // Domaine Patrimoine
  AccommodationList = 'SECTION_ACCOMMODATION_LIST',
  AddressList = 'SECTION_ADRESS_LIST',

  // Domaine spécial et administration
  // Utilisateurs = 'SECTION_UTILISATEURS',
  UserList = 'SECTION_USER_LIST',
  UserCreate = 'SECTION_USER_CREATE',
  DevIssuerOccupancySelectorCOP = 'SECTION_DEV_ISSUER_OCCUPANCY_SELECTOR_COP',

}