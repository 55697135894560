import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ContactService, IContactRequestOptions } from '../../contact.service';
import { Contact } from '../../contact.class';
import { ContactTitle } from '../../contact-title.class';
import { IContactDto } from '../../contact.dto';

@Component({
  selector: 'plugin-contact-edit',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
  ],
  templateUrl: './contact-edit.plugin.html',
  styleUrl: './contact-edit.plugin.scss'
})
export class ContactEditPlugin {
  public contact: Contact = new Contact();
  public titles: ContactTitle[] = [];
  private dataLoaded: boolean = false;

  @Input()
  set options(value: IContactRequestOptions) {
    this.loadData(value);
  }

  @Output() onSave: EventEmitter<IContactDto> = new EventEmitter();
  @Output() onCancel: EventEmitter<void> = new EventEmitter();

  constructor(private contactService: ContactService) {}

  ngOnInit(): void {
    this.loadTitles();
  }

  loadTitles(): void {
    this.contactService.personTitlesList().subscribe((titles: ContactTitle[]) => {
      console.log('loadTitles');
      this.titles = titles;
    });
  }

  loadData(options: IContactRequestOptions): void {
    if (!this.dataLoaded) {
      if (options.selectIds && options.selectIds.length>0) {
        this.contactService.get(options.selectIds[0]).subscribe(c => {
          if (c) {
            console.log('loadData contact');
            this.contact = c;
          }
        });
      } // sinon => new
      this.dataLoaded = true;
    } 
  }

  saveContact(): void {
    console.log('Saving contact', this.contact);
    console.log('remontée de contact ', this.contact.getDto());

    this.onSave.emit(this.contact.getDto());
  }

  cancel(): void {
    this.onCancel.emit();
  }
}
