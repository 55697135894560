<!--
la zone est divisée en une partie haute et une parie basse

partie haute :
divisée en deux parties : gauche et droite (50%/50%)
haut / gauche : liste des loyers à percevoir
haut / droite : liste des loyers perçus récemment

partie basse :
divisée en deux parties : gauche et droite
gauche : liste des baux actifs
droite : liste des opérations à émettre pour le bail sélectionné
-->

<!-- 
si écran étroit :
les zones sont disposées les unes en dessous des autres :
- liste des loyers à percevoir
- liste des loyers reçus récemment
- liste des baux actifs
- liste desopérations à émettre pour le bail sélectionné
-->
<h1>Gestion des loyers</h1>

<!-- Conteneur principal -->
<div class="container">
  
    <!-- Partie haute divisée en gauche et droite -->
    <div class="upper-container">
      <div class="left-side">
        <h1>Liste des loyers à percevoir</h1>
        <plugin-rent-picker [options]="dueRentOptions" (activated)="rentActivated($event)"></plugin-rent-picker>
      </div>
      <div class="right-side">
        <h1>Liste des loyers perçus récemment</h1>
        <!-- Composant pour loyers perçus récemment -->
        <plugin-rent-picker [options]="paidRentOptions" (activated)="rentActivated($event)"></plugin-rent-picker>
      </div>
    </div>
  
    <!-- Partie basse divisée en gauche et droite -->
    <div class="lower-container">
      <div class="left-side">                                           <!-- ******** Container bas / gauche -->
        <h1>Liste des baux actifs</h1>
        <plugin-lease-picker [options]="leaseOptions" (onSelect)="selectLease($event)" (onView)="manageContract($event)"></plugin-lease-picker>
        <!-- <plugin-lease-details [leaseObject]="lease"></plugin-lease-details> -->
      </div>
      
      <div class="right-side">
        <h1>Opérations à émettre pour le bail sélectionné</h1>
        <!-- Composant pour les opérations à émettre -->
        <plugin-rent-to-issue-picker [leaseDto]="lease.getDto()" (activated)="rentToIssueActivated($event)"></plugin-rent-to-issue-picker>
      </div>
    </div>
  
  </div>
  