<div class="occupancy-data-edit">
    <h2>Éditer les informations d'occupation</h2>
  
    <form (ngSubmit)="save()">
      <!-- Adresse -->
      <div class="form-group" *ngIf="occupancy.address">
        <label for="address">Adresse :</label>
        <!-- Puisque formattedAddress est une méthode, on l'appelle directement dans [value] -->
        <input type="text" id="address" [value]="occupancy.address.formattedAddress()" name="address" disabled />
      </div>
      
      <!-- Contact -->
      <div class="form-group">
        <label for="contact">Contact :</label>
        <input type="text" id="contact" [value]="occupancy.contact?.formattedName()" name="contact" disabled />
      </div>
  
      <!-- Address Usage (limité à 10 caractères) -->
      <div class="form-group">
        <label for="addressUsage">Usage de l'adresse :</label>
        <input
          type="text"
          id="addressUsage"
          [(ngModel)]="occupancy.addressUsage"
          name="addressUsage"
          maxlength="10"
        />
      </div>
  
      <!-- Additional Info -->
      <div class="form-group">
        <label for="additionalInfo">Informations supplémentaires :</label>
        <textarea id="additionalInfo" [(ngModel)]="occupancy.additionalInfo" name="additionalInfo"></textarea>
      </div>
  
      <!-- Date de début -->
      <div class="form-group">
        <label for="dtStart">Date de début :</label>
        <input type="date" id="dtStart" [(ngModel)]="occupancy.dtStart" name="dtStart" />
      </div>
  
      <!-- Date de fin -->
      <div class="form-group">
        <label for="dtEnd">Date de fin :</label>
        <input type="date" id="dtEnd" [(ngModel)]="occupancy.dtEnd" name="dtEnd" />
      </div>
  
      <!-- Boutons de sauvegarde et d'annulation -->
      <div class="action-buttons">
        <button type="submit" class="btn-save">Sauvegarder</button>
        <button type="button" class="btn-cancel" (click)="cancel()">Annuler</button>
      </div>
    </form>
  </div>
