import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from '../../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './login.route.html',
  styleUrl: './login.route.scss'
})
export class LoginRoute {
  loginForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
  ) {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  onSubmit() {
    if (this.loginForm.valid) {
      const { username, password } = this.loginForm.value;
      console.log('Identifiant:', username);
      console.log('Mot de passe:', password);

      this.authService.login({
        username: username,
        pwdc: password,
      }).subscribe(
        response => {
          this.authService.handleLoginResponse(response);
          this.router.navigate(['management/dashboard']);
        }
      )
    } else {
      console.log('Formulaire invalide');
    }
  }
}
