<h1 class="title">Contacts</h1>

<div class="contact-list">
    <plugin-contact-picker 
    [options]=(options)
    (activated)="viewContact($event)"></plugin-contact-picker>
</div>

<div class="global-actions">
    <button class="btn-add-contact" (click)="addNewContact()">
        <img src="assets/btn/btn-new-contact-256.png" alt="Créer un nouveau contact">
        <span>Créer un nouveau contact</span>
    </button>
</div>
