import { Injectable } from '@angular/core';
import { INavItem } from './nav.item';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ERoleCode } from '../user/roles.enum';
import { ETopic } from './topics.enum';
import { ESection } from './sections.enum';
import { Tag } from '../tag/tag.class';

@Injectable({
  providedIn: 'root'
})
export class NavService {
  private topics: INavItem[] = [
    { id: ETopic.Management, 
      label: 'Gestion', 
      route: 'management', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN, ERoleCode.GEST, ERoleCode.LOCAT],
    },
    { id: ETopic.Rental, 
      label: 'Location', 
      route: 'rental', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN, ERoleCode.GEST, ERoleCode.LOCAT],
    },
    { id: ETopic.Services, 
      label: 'Prestation', 
      route: 'services', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN, ERoleCode.GEST],
    },
    { id: ETopic.Wealth, 
      label: 'Patrimoine', 
      route: 'wealth', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN, ERoleCode.GEST],
    },
    { id: ETopic.Administration, 
      label: 'Administration', 
      route: 'user', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN],
    },
    { id: ETopic.Dev, 
      label: 'Dev', 
      route: 'dev', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN],
    },
  ];

  private sections: INavItem[] = [
    // Domaine Location
    { id: ESection.ContactList, 
      parentId: ETopic.Rental, 
      label: 'Contacts', 
      route: 'rental/contact/list', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN, ERoleCode.GEST] 
    },
    { id: ESection.LeaseList, 
      parentId: ETopic.Rental, 
      label: 'Baux', 
      route: 'lease/list', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN, ERoleCode.GEST] 
    },

    // Domaine Prestation
    { id: ESection.ContactList, 
      parentId: ETopic.Services, 
      label: 'Contacts', 
      route: 'services/contact/list', // Filtre ?
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN, ERoleCode.GEST] 
    },
    { id: ESection.ContactList, 
      parentId: ETopic.Services, 
      label: 'Contrats', 
      route: 'services/contract/list', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN, ERoleCode.GEST] 
    },

    // Domaine Gestion
    { id: ESection.Collection, 
      parentId: ETopic.Management, 
      label: 'Loyers', 
      route: 'management/dashboard', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN, ERoleCode.GEST] 
    },

    // Domaine Patrimoine
    { id: ESection.AccommodationList, 
      parentId: ETopic.Wealth, 
      label: 'Logements', 
      route: 'accommodation/list', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN, ERoleCode.GEST] 
    },
    
    { id: ESection.AddressList, 
      parentId: ETopic.Wealth, 
      label: 'Adresses', 
      route: 'address/list', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN, ERoleCode.GEST] 
    },

    // Domaine administration 
    { id: ESection.UserCreate, 
      parentId: ETopic.Administration, 
      label: 'Nouveau', 
      route: 'user/create', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN, ERoleCode.GEST] 
    },
    { id: ESection.UserList, 
      parentId: ETopic.Administration, 
      label: 'Liste', 
      route: 'user/list', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN, ERoleCode.GEST] 
    },

    // Domlaine spécial (Dév)
    { id: ESection.DevIssuerOccupancySelectorCOP, 
      parentId: ETopic.Dev, 
      label: 'IsserOccupancySelectorCOP', 
      route: 'dev/issuer-occupancy-selector-cop', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN] 
    },
  ];

  private selectedTopicIdSubject = new BehaviorSubject<string | null>(null);
  public selectedTopicId$: Observable<string | null> = this.selectedTopicIdSubject.asObservable();

  constructor() {}

  // Méthode pour filtrer les topics selon les rôles
  getFilteredTopics(roles: string[]): INavItem[] {
    return this.topics.filter(topic => topic.rolesAllowed?.some(role => roles.includes(role)));
  }

  getFilteredSections(roles: string[], topicId: string): Observable<INavItem[]> {
    const filteredSections = this.sections.filter(section =>
      section.parentId === topicId && section.rolesAllowed?.some(role => roles.includes(role))
    );
    return of(filteredSections); // Retourner un Observable ici
  }

  // Méthode pour changer de topic
  selectTopic(topicId: string): void {
    this.selectedTopicIdSubject.next(topicId);
  }

  // retourne un tag prêt à l'emploi à partir du topic en cours
  getTagBU(): Tag {
    const currentTopicId = this.selectedTopicIdSubject.getValue();
    console.log('Current topic ID:', currentTopicId);
    const tag = new Tag();
    tag.free = false;
    console.log('Initial tag object:', tag);

    switch(currentTopicId) {
      case ETopic.Management:
        tag.label = '';
        console.log('Tag for Management:', tag);
        break;
      case ETopic.Rental:
        tag.label = '_BU_RENTAL_';
        console.log('Tag for Rental:', tag);
        break;
      case ETopic.Services:
        tag.label = '_BU_SERVICES_';
        console.log('Tag for Services:', tag);
        break;
      case ETopic.Wealth:
        tag.label = '';
        console.log('Tag for Wealth:', tag);
        break;
      case ETopic.Administration:
        tag.label = '';
        console.log('Tag for Administration:', tag);
        break;
      case ETopic.Dev:
        tag.label = '';
        console.log('Tag for Dev:', tag);
        break;
      default:
        tag.label = ''; // Valeur par défaut si le topic n'est pas reconnu
        console.log('Default tag for unrecognized topic:', tag);
    }

    console.log('Returning tag:', tag);
    return tag;
  }
}
