import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { IOperationDto } from './operation.dto';
import { environment } from '../../environments/environment';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OperationService {

  constructor(
    private apiService: ApiService,
  ) { }

  get(idoperation: number): Observable<IOperationDto | null> {
    const url = `${environment.api.url}${environment.api.endpoint.operationGet}/${idoperation}`;
    return this.apiService.get<IOperationDto>(url).pipe(
      catchError(this.apiService.handleError.bind(this)),
    );
  }

  list(options: IOperationRequestOptions = {}): Observable<IOperationDto[] | null> {
    const url = `${environment.api.url}${environment.api.endpoint.operationList}`;
    
    let params = new HttpParams();
    
    // Ajoute les paramètres de requête en fonction des propriétés d'options
    if (options.inStatus && options.inStatus.length > 0) {
      params = params.set('ins', options.inStatus.join(',')); // Liste des status inclus
    }
    
    if (options.exStatus && options.exStatus.length > 0) {
      params = params.set('exs', options.exStatus.join(',')); // Liste des status exclus
    }
    
    if (options.idOperation !== undefined) {
      params = params.set('ido', options.idOperation.toString()); // ID de l'opération
    }
    
    if (options.classParent) {
      params = params.set('par', options.classParent); // Classe parent
    }
    
    if (options.idParent !== undefined) {
      params = params.set('idp', options.idParent.toString()); // ID parent
    }
    
    if (options.typeOperation) {
      params = params.set('top', options.typeOperation); // Type d'opération
    }

    // Ajoute les paramètres de date min et max si définis
    if (options.dateMin) {
      params = params.set('min', options.dateMin.toISOString()); // Date minimum au format ISO
    }

    if (options.dateMax) {
      params = params.set('max', options.dateMax.toISOString()); // Date maximum au format ISO
    }
    
    console.log('URL:', url);
    console.log('Query Params:', params.toString()); // Log pour vérifier les params
    
    return this.apiService.get<IOperationDto[]>(url, { params }).pipe(
      catchError(error => {
        console.error('Erreur lors de la récupération des opérations', error);
        return this.apiService.handleError(error);
      })
    );
  }

  
  save(opDto: IOperationDto): Observable<IOperationDto | null> {
    const url:string = `${environment.api.url}${environment.api.endpoint.operationSave}`;
    return this.apiService.post<IOperationDto>(url, opDto).pipe(
      catchError(this.apiService.handleError.bind(this)),
    );
  }
}

export interface IOperationRequestOptions {
  inStatus?: string[],
  exStatus?: string[],
  idOperation?: number,
  classParent?: string,
  idParent?: number,
  typeOperation?: string,
  dateMin?: Date;
  dateMax?: Date;
}
