import { Component, OnInit } from '@angular/core';
import { Lease } from '../../lease.class';
import { ActivatedRoute, Router } from '@angular/router';
import { ILeaseRequestOptions, LeaseService } from '../../lease.service';
import { CommonModule } from '@angular/common';
import { LeaseEditPlugin } from '../../plugin/edit/lease-edit.plugin';
import { ILeaseDto } from '../../lease.dto';
import { LeaseDataViewPlugin } from '../../plugin/data-view/lease-data-view.plugin';
import { LeaseDataEditPlugin } from '../../plugin/data-edit/lease-data-edit.plugin';
import { Address } from '../../../../wealth/address/address.class';
import { AddressService } from '../../../../wealth/address/address.service';

@Component({
  selector: 'route-lease-edit',
  standalone: true,
  imports: [
    CommonModule,
    LeaseEditPlugin,
    LeaseDataViewPlugin,
    LeaseDataEditPlugin,
  ],
  templateUrl: './lease-edit.route.html',
  styleUrl: './lease-edit.route.scss'
})
export class LeaseEditRoute implements OnInit {
  lease: Lease = new Lease();
  loaded: boolean = false;

  // leaseEditMode: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private leaseService: LeaseService,
    private addressService: AddressService,
  ) {}

  ngOnInit() {
    if (!this.loaded) {
      this.route.paramMap.subscribe(params => {
        const id = +(params.get('id') || 0);
        this.loadLease(id);
      });
    }
  }

  loadLease(id: number = 0) {
    console.log('Chargement du bail avec ID:', id);
    
    if (id === 0) {
        console.log('ID est 0, création d\'un nouveau bail');
        this.lease = new Lease();
        this.loaded = true;
        console.log('Nouveau bail créé, mode chargé:', this.loaded);
    } else {
        console.log('Chargement du bail existant avec ID:', id);
        
        const options: ILeaseRequestOptions = {
            loadAccommodation: true,
            loadLessors: true,
            loadTenants: true,
            loadOperations: true,
        };
        console.log('Options de chargement:', options);

        this.leaseService.get(id, options).subscribe(
            l => {
                if (l) {
                    console.log('Données du bail reçues:', l);
                    this.lease = l;
                } else {
                    console.log('Aucun bail trouvé, création d\'un nouveau bail');
                    this.lease = new Lease();
                }
                this.loaded = true;
                console.log('Chargement terminé, mode chargé:', this.loaded);
            },
            error => {
                console.error('Erreur lors du chargement du bail:', error);
                this.loaded = true;
            }
        );
    }
  }


  cancelDataEdit() {
    this.toggleLeaseMode();
  }

  toggleLeaseMode() {
    // this.leaseEditMode = !this.leaseEditMode;
    // console.log('Mode édition du bail:', this.leaseEditMode);
  }

  save(dto: ILeaseDto) {
    this.lease = new Lease(dto);
    this.leaseService.save(this.lease).subscribe(l => this.lease = l || new Lease());
    this.router.navigate(['lease/list']);
  }
}