import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, catchError, map, of, throwError } from 'rxjs';
import { ContactTitle } from './contact-title.class';
import { ApiService } from '../api/api.service';
import { Contact } from './contact.class';
import { environment } from '../../environments/environment';
import { IContactDto, IContactTitleDto } from './contact.dto';
import { Occupancy } from './occupancy/occupancy.class';
import { IOccupancyDto } from './occupancy/occupancy.dto';
import { NavService } from '../nav/nav.service';


@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private personTitleCache: ContactTitle[] | null = null;

  constructor(
    private apiService: ApiService,
    // private navService: NavService,
  ) {}

  // création d'un contact
  create(contact: Contact): Observable<Contact | null> {
    const url = `${environment.api.url}${environment.api.endpoint.personCreate}`;
    const dto = contact.getDto(); // Obtenez le DTO
  
    // Log pour vérifier le contenu du DTO
    console.log('DTO envoyé:', dto);
  
    // S'il y a des champs à ajouter ou à modifier, faites-le ici
    // Par exemple, ajouter un champ ou convertir des données :
    // dto.someField = 'someValue';
  
    // Maintenant, vous envoyez le DTO modifié ou vérifié
    return this.apiService.post<IContactDto>(url, dto).pipe(
      map(dtoResponse => {
        // Vous pouvez également vérifier ou modifier la réponse ici avant de créer le Contact
        console.log('DTO reçu:', dtoResponse);
        return new Contact(dtoResponse);
      }),
      catchError(error => {
        console.error('Erreur lors de la création du contact:', error);
        return of(null); // Ou une autre gestion d'erreur selon vos besoins
      })
    );
  }

  get(
    id: number, 
    options: IContactRequestOptions = {},
  ): Observable<Contact | null> {
    const url:string = `${environment.api.url}${environment.api.endpoint.personGet}/${id}`;
    let params: HttpParams = new HttpParams()
      .set('ttl', options.loadTitle ? 1 : 0);
    return this.apiService.get<IContactDto>(url, { params }).pipe(
      map(dto => new Contact(dto))
    )
  }

  /**
   * *************************************************************************
   *                                                  getContactAddressList **
   * *************************************************************************
   * retourne les adresses occupées par un contact
   * @param idperson 
   * @returns IContactAddressEntity[] --> passer à un Dto
   */
  occupancyList(idperson: number): Observable<Occupancy[]> {
    const url = `${environment.api.url}${environment.api.endpoint.occupancyList}/p/${idperson}`;
    return this.apiService.get<IOccupancyDto[]>(url).pipe(
      map(dtos => dtos.map(dto => new Occupancy(dto)))
    );
  }

  personTitlesList(): Observable<ContactTitle[]> {
    const url: string = `${environment.api.url}${environment.api.endpoint.personTitleList}`;
    return this.apiService.get<IContactTitleDto[]>(url).pipe(
      map(dtos => {
        const titles = dtos.map(dto => new ContactTitle(dto));
        return titles;
      })
    );
  }
  
  
  list(
    options: IContactRequestOptions = {},
  ): Observable<Contact[]> {
    const url = `${environment.api.url}${environment.api.endpoint.personList}`;
    
    // Initialisation des paramètres
    let params = new HttpParams()
    .set('ttl', options.loadTitle ? +options.loadTitle : 0);

    if (options.selectIds && options.selectIds.length > 0 && !(options.ignoreSelectIds && options.ignoreSelectIds)) {
      const idsString = options.selectIds.join(',');
      params = params.set('ids', idsString);
    }
  
    if (options.selectTags && options.selectTags.length > 0) {
      const tagsStr = options.selectTags.join(',');
      params = params.set('itg', tagsStr);
    }
  
    // Appel de l'API et suivi des réponses
    return this.apiService.get<IContactDto[]>(url, { params }).pipe(
      map(l => {
        return l.map(d => {
          const contact = new Contact(d);
          return contact;
        });
      }),
      // Ajout d'une gestion d'erreurs basique avec log
      catchError((error) => {
        console.error('Error fetching contact list:', error);
        return throwError(error);
      })
    );
  }
  

  update(contact: Contact): Observable<Contact | null> {
    const url = `${environment.api.url}${environment.api.endpoint.personUpdate}/${contact.id}`;
    return this.apiService.patch<IContactDto>(url, contact.getDto()).pipe(
        map(dto => new Contact(dto))
      );
  }

}

export interface IContactRequestOptions {
  firstName?: string,
  lastName?: string,
  surname?: string,
  loadTitle?: boolean,
  loadOwerships?: boolean,
  loadLessors?: boolean,
  loadTenants?: boolean,
  
  selectIds?: number[],
  ignoreSelectIds?: boolean,          // ignore la sélection par selectIds
  selectTags?: string[],

  showDelete?: boolean,
  showEdit?: boolean,
}
